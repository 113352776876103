<template>
	<div class="form form-horizontal form-scrollable">
		<div class="modal-body">
			<div class="form-row">
				<!-- EMPRESA -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="enterprise_id">Empresa</label>
					<select name="enterprise_id" class="form-select form-select-sm" v-model="campu.enterprise_id" v-validate="'required'" data-vv-as="'Empresa'">
						<option :value="null">Seleccionar</option>
						<option :value="item.id" v-for="(item, index) in enterprises" :key="index">{{ item.denomination }}</option>
					</select>
					<span v-show="errors.has('enterprise_id')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('enterprise_id') }} </span>
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="campu.enterprise_id" />
				</div>
				<!-- NOMBRE -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="name">Nombre</label>
					<input
						autocomplete="off"
						v-model="campu.name"
						type="text"
						name="name"
						class="form-control form-control-sm text-uppercase"
						v-validate="'required'"
						data-vv-as="'Nombre'" />
					<span v-show="errors.has('name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('name') }} </span>
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="campu.name" />
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="campu" />
				</div>
				<!-- DIRECCION -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="address">Dirección</label>
					<input
						autocomplete="off"
						v-model="campu.address"
						type="text"
						name="address"
						class="form-control form-control-sm text-uppercase"
						v-validate="'required'"
						data-vv-as="'Dirección'" />
					<span v-show="errors.has('name')" class="form-group__error"> <i class="fas fa-exclamation-circle"></i> {{ errors.first('address') }} </span>
					<ErrorBackendComponent :errorsBackend="errorsBackend" name="campu.address" />
				</div>
				<!-- RED -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="red">Red</label>
					<input v-model="campu.red" name="red" type="text" class="form-control form-control-sm" />
				</div>
				<!-- DISTRITO -->
				<div class="form-group col-md-12">
					<label class="col-form-label label-modal" for="district">Distrito</label>
					<input v-model="campu.district" name="district" type="text" class="form-control form-control-sm" />
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button @click="mtd_send" class="btn btn-sm btn-primary mr-3">{{ propInfo.data.buttonSubmit }}</button>
			<button class="btn btn-sm btn-secondary" @click="mtd_close">Cancelar</button>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
	props: {
		propInfo: {
			required: true,
		},
	},
	components: {},
	data() {
		return {
			campu: {
				id: null,
				enterprise_id: null,
				name: null,
				red: '',
				district: '',
			},
			enterprises: [],
			errorsBackend: {},
			methods: {
				init: {
					create: 'this.mtd_onCreate()',
					edit: 'this.mtd_onEdit()',
				},
				action: {
					create: 'this.mtd_store()',
					edit: 'this.mtd_update()',
				},
			},
		};
	},
	computed: {
		...mapGetters(['url_api']),
		cpd_btnDisabled() {
			return this.errors.any();
		},
	},
	mounted() {
		eval(this.methods.init[this.propInfo.data.method]);
	},
	methods: {
		...mapActions(['get', 'post', 'update']),
		mtd_onCreate: function () {
			this.get({
				url: this.url_api + '/campus/requirements',
			})
				.then(response => {
					this.enterprises = response.enterprises;
				})
				.catch(() => {});
			this.$emit('onload', { status: 'success' });
		},
		mtd_onEdit: function () {
			this.get({
				url: this.url_api + '/campus/requirements',
			})
				.then(response => {
					this.enterprises = response.enterprises;
				})
				.catch(() => {});
			this.mtd_setData(this.propInfo.data.row);
			this.$emit('onload', { status: 'success' });
		},
		mtd_store: function () {
			this.post({ url: `${this.url_api}/campus`, params: { campu: this.campu } })
				.then(res => {
					this.$emit('success', { component: this.propInfo.component, res: { campu: res.campu }, action: 'store' });
					window.message('success', 'Registro exitoso');
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_update: function () {
			this.update({ url: `${this.url_api}/campus/${this.campu.id}`, params: { campu: this.campu } })
				.then(res => {
					this.$emit('success', { component: this.propInfo.component, res: { campu: res.campu }, action: 'update' });
					window.message('success', 'Actualización exitosa');
				})
				.catch(errors => (this.errorsBackend = errors));
		},
		mtd_setData(resource) {
			this.campu.id = resource.id;
			this.campu.enterprise_id = resource.enterprise_id;
			this.campu.name = resource.name;
			this.campu.address = resource.address;
			this.campu.red = resource.red;
			this.campu.district = resource.district;
		},
		$eval(expr) {
			return eval(expr);
		},
		mtd_close: function () {
			this.$emit('close');
		},
		mtd_send: function () {
			this.submitted = true;
			var _this = this;
			this.$validator.validate().then(valid => {
				if (valid) {
					_this.$eval(_this.methods.action[this.propInfo.data.method]);
				}
			});
		},
	},
};
</script>
